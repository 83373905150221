<template>
  <div class="poPup">
    <div class="desc">
      <div class="img"></div>
      <div class="img1"></div>
      <div class="img2"></div>
      <div class="img3"></div>
      <div class="img4"></div>
      <div class="img5"></div>
      <div class="text">
        {{ $t("TaskPopup[29]") }}
        <!-- 沙漏图标 -->
        <img src="../../assets/img/shalou.png" alt="" width="55" height="55" />
        <div>Loading...</div>
      </div>
    </div>
    <!-- 底部按钮部分 -->
    <div class="buttom">
      <!-- 关闭页面 -->
      <div @click="close()">
        <div>{{ $t("TaskPopup[30]") }}</div>
      </div>
      <!-- 邀请好友 -->
      <div @click="$router.push('/user/promote')">
        {{ $t("TaskPopup[18]") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "autoEnabled",
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.poPup {
  width: 350px;
  height: max-content;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.poPup > .desc {
  position: relative;
  z-index: 9;
  display: flex;
  justify-content: center;
  width: 263px;
  height: 272px;
  border: dashed 1px #e18e4a;
  border-radius: 10px;
  background: linear-gradient(180deg, #ffe4b5, #ffe4b5, #f6c791);
  box-shadow: inset 0px 10px 13px #eb8f43;
  overflow: hidden;
}

.poPup > .desc > .img {
  width: 150px;
  height: 150px;
  position: absolute;
  top: -46%;
  left: 57%;
  background: url("../../assets/img/yanhua.png") no-repeat;
  background-size: cover;
}
.poPup > .desc > .img1 {
  position: absolute;
  left: -17%;
  z-index: 9;
  width: 64px;
  height: 37px;
  background: url("../../assets/img/hspzd.png") no-repeat;
  background-size: cover;
}
.poPup > .desc > .img2 {
  width: 50px;
  height: 30px;
  position: absolute;
  right: -17%;
  z-index: 9;
  background: url("../../assets/img/hspz.png") no-repeat;
  background-size: cover;
}
.poPup > .desc > .img3 {
  width: 53.21px;
  height: 50.74px;
  transform: rotate(17.67863deg);
  position: absolute;
  left: -12%;
  top: 30%;
  z-index: 9;
  background: url("../../assets/img/jbd.png") no-repeat;
  background-size: cover;
}
.poPup > .desc > .img4 {
  width: 39.4px;
  height: 37.55px;
  transform: rotate(-27.054363deg);
  position: absolute;
  right: -8%;
  bottom: 10%;
  background: url("../../assets/img/jbx.png") no-repeat;
  background-size: cover;
}
.poPup > .desc > .img5 {
  position: absolute;
  width: 390px;
  height: 149.52px;
  background: url("../../assets/img/pd.png") no-repeat;
  background-size: cover;
}
.poPup > .desc > .text {
  padding: 30px 15px 0 15px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 243.31px;
  height: 251.38px;
  background: linear-gradient(180deg, #ffffff, #ffffff, #ffe9d8);
  box-shadow: 0px 0.62px 4.96px #926d47;
}
.poPup > .desc > .text > img {
  margin-top: 25px;
}
.poPup > .desc > .text > div {
  padding: 15px;
  color: #9e5029;;
  font-size: 14px;
  font-weight: 400;
}
.poPup > .desc > div > .title {
  position: absolute;
  top: -8%;
  width: 142px;
  height: 34px;
  color: #9e5029;

  font-size: 21px;
  font-weight: 400;
  line-height: 34px;
  text-align: center;
  border-radius: 10px;
  background: linear-gradient(90deg, #f5cd87, #f0b664);
  box-shadow: inset 0px 1px 1px #e6a64e;
}
.poPup > .desc > div > .debance {
  width: 100%;
  margin-top: 8%;
}
.poPup > .desc > div > .debance > p {
  color: #9e5029;

  font-size: 14.896831px;
  font-weight: 400;
  line-height: 35px;
  text-align: center;
}
.poPup > .buttom {
  display: flex;
  margin-top: -25px;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  position: relative;
  width: 321px;
  height: 212px;
  background: url("../../assets/img/box.png") no-repeat center/contain;
}
.poPup > .buttom > div:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 247px;
  height: 58px;
  border-radius: 12px;
  border: solid #feeac5;
  background: linear-gradient(180deg, #edb45d, #eab662);
}
.poPup > .buttom > div:nth-child(2) {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 247px;
  height: 45px;
  border-radius: 12px;
  border: solid #feeac5;
  background: linear-gradient(180deg, #edb45d, #eab662);
}
.poPup > .buttom > div:nth-child(2) > div {
  width: 237px;
  height: 35px;
  border-radius: 14px;
  border: solid 1px #d94a11;
  text-align: center;
  color: #ffffff;

  font-size: 18px;
  font-weight: 400;
  line-height: 35px;
  background: linear-gradient(180deg, #ffaa5a, #ff5a00);
}
.poPup > .buttom > div:first-child > div {
  width: 237px;
  height: 48px;
  border-radius: 14px;
  border: solid 1px #d94a11;
  text-align: center;
  color: #ffffff;

  font-size: 24.82805px;
  font-weight: 400;
  line-height: 48px;
  background: linear-gradient(180deg, #ffaa5a, #ff5a00);
}
.poPup > .buttom > div:last-child {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 247px;
  height: 58px;
  border-radius: 12px;
  border: solid #feeac5;
  text-align: center;
  color: #ffffff;

  font-size: 24.82805px;
  font-weight: 400;
  /* line-height: 58px; */
  background: linear-gradient(180deg, #edb45d, #eab662);
}
</style>
