<template>
  <div class="autoComplete_n">
    <div class="title">{{ $t("TaskPopup[19]") }}</div>
    <img
      src="../../assets/img/openAutoCoplete_n.png"
      alt=""
      width="96"
      height="96"
    />
    <!-- 提示信息 -->
    <div class="tips">{{ $t("TaskPopup[20]") }}</div>
    <!-- 开通自动抢单按钮 -->
    <div class="btn">
      <div @click="openzd">{{ $t("TaskPopup[16]") }}</div>
    </div>
  </div>
</template>

<script>
//未开通 自动抢单 的弹窗
export default {
  name: "autoComplete_n",
  data() {
    return {};
  },
  methods: {
    openzd() {
      this.$emit("openzd");
    },
  },
};
</script>

<style scoped>
.autoComplete_n {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 258px;
  height: 286px;
  padding: 0 15px;
  border-radius: 20px;
  border: dashed 1px rgba(169, 169, 169, 0.4);
  background: #ffffff;
}
.autoComplete_n > .title {
  color: #0e0e0e;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
}
.autoComplete_n > .tips {
  color: #0e0e0e;

  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
}
.autoComplete_n > .btn > div:first-child {
  margin-top: 15px;
  width: 170px;
  height: 33px;
  border-radius: 8px;
  background: #5e59e9;
  color: #ffffff;

  font-size: 18px;
  font-weight: 400;
  line-height: 33px;
  text-align: center;
}
</style>
